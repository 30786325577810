body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*netsuite release */

.integrationdiv input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.integrationdiv input[type="radio"]:hover {
  cursor: pointer;
}

.integrationdiv .logoLabel:hover {
  cursor: pointer;
}

.integrationdiv input[type="radio"]:checked {
  appearance: none;
  width: 32px;
  height: 32px;
  margin: 0px auto;
  background-color: rgb(51, 194, 223);
  border-radius: 50%;
  padding: 1px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  border-width: 0px;
  visibility: visible;
}

.integrationdiv input[type="radio"]:checked::before {
  position: absolute;
  color: white !important;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
  font-size: 21px;
  top: 7px;
}

.container {
  max-width: calc(100% - 211px) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #878787;
}

/* graph export fix */
@media print {
  .pdfExport {
    width: 500px !important;
    height: 500px !important;
  }
}

.TW {
  width: 100% !important;
  max-width: 100vw;
}

typehascls .table-wrapper thead th:nth-child(2) {
  width: 2%;
  text-align: center;
}

.typehascls .table-wrapper thead th:nth-child(1) {
  width: 22%;
}

.typehascls .table-wrapper thead th:nth-child(3) {
  width: 7%;
}

.typehascls .table-wrapper tbody td:nth-child(2) {
  text-align: center !important;
}

.right_input_inner {
  width: 100%;
}

.right_input {
  width: 150px;
}

.cheap_wrp {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 10px;
}

.cheap_wrp_new {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  width: 50%;
  margin-top: 15px;
}

.right_input .react-select__control,
.right_input .react-select__menu {
  border: 1px solid #989393cc !important;
}

.right_input .react-select__control svg path {
  fill: #606060;
}

.right_input .react-select__control {
  height: 30px !important;
}

.right_input .react-select__indicator {
  margin-left: auto;
}

.right_input .react-select__indicator svg {
  width: 11px !important;
  height: 10px !important;
}

.comments_list {
  max-height: 80px;
  overflow-y: auto;
}

.comments_list::-webkit-scrollbar {
  /* display: none; */
}

.card-body .col .form-field .error-message {
  margin-left: 10px;
}

.hqaLpb .greetings .logo-image {
  height: auto;
  width: 40px;
}

.wrappericon {
  display: flex;
  justify-content: center;
}

.wrappericon div {
  display: inline;
  margin-left: 20px;
  text-align: right;
  margin-right: 0px;
}

.wrappericon span {
  width: 100px;
}

.linear-activity {
  overflow: hidden;
  width: 50%;
  height: 4px;
  background-color: #b3e5fc;
  margin: 20px auto;
}

/* .determinate {
  position: relative;
  max-width: 100%;
  height: 100%;
  -webkit-transition: width 500ms ease-out 1s;
     -moz-transition: width 500ms ease-out 1s;
       -o-transition: width 500ms ease-out 1s;
          transition: width 500ms ease-out 1s;
  background-color: #03A9F4;
} */

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #03a9f4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #4fc3f7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

.errortext,
.card-body .row .glhyef {
  color: #ff3f3f;
  margin: 0;
  font-size: 14px;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

.card-header div input {
  margin-right: 5px;
}

.disputebtnss {
  display: flex;
}

.disputebtnss button {
  height: auto;
  width: 70px;
  font-size: 14px;
  border: 1px solid #707070 !important;
  padding: 2px;
  margin-right: 5px;
  border-radius: 0px;
  background-color: #3a3b48 !important;
  color: #909090;
}

.disputebtnss button:hover {
  background-color: #33c2df !important;
  color: #000 !important;
}

.disputebtnss .checked {
  background-color: #33c2df !important;
  border-color: #3a3b48 !important;
  color: #000 !important;
}

.new_cheap_flex {
  width: 100%;
}

.odd_even_cls {
  padding: 10px;
  max-height: 150px;
  border: 1px solid #707070;
}

.odd_even_cls .comment_wrapper {
  padding: 10px;
  flex-direction: column;
}

.odd_even_cls .modal-text {
  margin-left: 0;
}

.odd_even_cls .comment_wrapper:nth-child(odd) {
  /* background: #363636a8; */
  /*   border-bottom: 1px solid white; */
}

.odd_even_cls .comment_wrapper:nth-child(even) {
  /* background: #5c5c5cb8; */
  /*   border-bottom: 1px solid white; */
}

.unseencomment {
  background: #42494e;
}

.logout-popup {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.popup-text {
  font-size: 16px;
  line-height: 1.5;
}

.popup-buttons {
  margin-top: 20px;
}

.stay-logged-in-button,
.logout-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.stay-logged-in-button {
  background-color: #43a047;
  color: white;
  margin-right: 10px;
}

.logout-button {
  background-color: #e53935;
  color: white;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment_extarea .error-message {
  position: absolute;
  left: 13px;
  bottom: -10px;
}

.pop-list-links {
  display: flex;
  flex-direction: column;
}

.comments .comment {
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  border-radius: 10px;
  font-size: 11px;
  padding: 10px 15px;
}

.comments [class*="level-"] .photo:before {
  background-color: #fff;
}

.comments .meta {
  color: white;
  font-weight: 900;
}

.comments .meta a {
  color: inherit;
}

.comments .body {
  color: white;
}

/**
 * Comments Thread
 */
.comments {
  list-style-type: none;
  padding: 5px 0 0 46px;
  position: relative;
  margin: 0 0 0 12px;
}

.comments:before,
.comments .comment,
.comments .comment:after,
.comments .comment:before,
.comments .photo img,
.comments [class*="level-"] .photo:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.comments:after,
.comments:before {
  display: block;
  content: "";
  position: absolute;
}

.comments:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 1px 3px rgba(0, 0, 0, 0.4) inset;
  border-width: 4px;
  border-style: solid;
  border-radius: 18px;
  height: 10px;
  width: 10px;
  left: -5px;
  top: -16px;
  z-index: -1;
}

.comments .comment {
  margin-bottom: 10px;
  position: relative;
}

.comments .comment:after,
.comments .comment:before {
  border-radius: 10px;
  position: absolute;
  display: block;
  content: "";
}

.comments .comment:after {
  width: 12px;
  height: 12px;
  left: -14px;
  top: 7px;
  background-color: white;
}

.comments .comment:before {
  width: 5px;
  height: 5px;
  left: -22px;
  top: 16px;
  background-color: white;
}

.comments .photo {
  position: absolute;
  left: -60px;
  top: 2px;
}

.comments .photo img {
  border: 1px solid #fff;
  border-radius: 32px;
  overflow: hidden;
  width: 33px;
  height: 33px;
}

.comments .meta {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.comments .meta .reply svg {
  width: 18px;
  height: 18px;
  fill: white;
}

.comments .meta .reply svg:hover {
  cursor: pointer;
}

.comments [class*="level-"] .photo:before {
  display: block;
  content: "";
  position: absolute;
  margin-top: -2px;
  height: 4px;
  width: 20px;
  left: -10px;
  top: 50%;
  z-index: -1;
}

.comment-wrapper {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.noBarClick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.comment_extarea .error-message,
.reminder_textarea .error-message {
  position: absolute;
  left: 13px;
  bottom: -10px;
}

.activedisputebtn {
  background-color: #33c2df !important;
}

/* .page-body {
  min-height: 400px;
} */
.right_input_inner .react-select__control {
  height: 35px !important;
}

.react-daterange-picker__wrapper {
  justify-content: center;
}

.OnTimeAndAgeStageFilter {
  color: #10c065;
}

.AgeWorseFilter {
  color: #e5b623;
}

.DefaultRiskFilter {
  color: #fa2020;
}

.comments_list::-webkit-scrollbar {
  background-color: #000;
  width: 10px;
  height: 40px;
  border-radius: 40px;
  display: block;
  margin-top: 10px;
}

.comments_list::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.41);
  border-radius: 35px;
  height: 20px;
}

.modal-content .comment-wrapper::-webkit-scrollbar {
  background-color: #000;
  width: 8px;
  display: block;
}

.modal-content .comment-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.41);
}

/**************For Payement Optimization Section Start*************/

.payble_select .react-select__control {
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  color: #909091;
  height: 40px;
  width: 100%;
  padding: 5px;
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  color: #7e7e7e;
  opacity: 1;
  cursor: pointer;
  margin: 0;
  background-position: calc(100% - 15px) center !important;
}

.payment_edit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.payableCalender .react-calendar {
  background-color: #2e2f38 !important;
}

.payableCalender .react-calendar__tile:disabled {
  background-color: #66666638 !important;
}

.payableCalender .react-calendar__navigation button:disabled {
  background-color: #66666638 !important;
}

.react-calendar__navigation button {
  color: white !important;
}

.payableCalender .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #181515ba !important;
}

.payableCalender
  .react-calendar__navigation
  button:enabled:hover
  .react-calendar__navigation__label__labelText {
  color: white !important;
}

.payableCalender
  .react-calendar__navigation
  button:enabled:hover
  .react-calendar__navigation__label__labelText {
  color: white !important;
}

.up-icon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #2dc635;
  display: inline-block;
}

.down-icon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #cc0808;
  /* You can change the color as needed */
  display: inline-block;
}

.payble_select .ReactVirtualized__List::-webkit-scrollbar {
  background-color: #000;
  width: 10px;
  height: 40px;
  border-radius: 40px;
  display: block;
  margin-top: 10px;
}

.payble_select .ReactVirtualized__List::-webkit-scrollbar-track {
  background-color: #000;
}

.payble_select .ReactVirtualized__List::-webkit-scrollbar-thumb {
  background: #888;
}

.payableCalender .react-daterange-picker__inputGroup__leadingZero {
  font-size: 11px;
}

.payble_select .react-select__multi-value {
  background-color: #232631 !important;
  margin: 0 !important;
  border-radius: 11px !important;
}

.payble_select .react-select__multi-value__label {
  color: #fff !important;
}

.payble_select .react-select__control {
  height: auto !important;
}

.payble_select .react-select__value-container {
  min-height: 30px;
}

.demo_class div {
  width: 100%;
}

.demo_class .DateRangePickerInput_arrow {
  width: auto;
}

.demo_class .DateRangePickerInput {
  display: flex !important;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid grey !important;
  border-radius: 20px;
  padding: 7px;
  justify-content: center;
  height: auto !important;
  background-color: transparent !important;
}

.demo_class input {
  background-color: #3a3b48 !important;
}

.demo_class .DayPickerNavigation,
.demo_class .DayPickerNavigation_button,
.demo_class .DayPicker_weekHeader,
.demo_class .CalendarMonthGrid_month__horizontal,
.demo_class .CalendarMonthGrid {
  width: auto !important;
}

.demo_class .DayPicker_weekHeader_li {
  display: none;
}

/**************For Payement Optimization Section End*************/

#mail-info-font {
  font-size: large;
  font-weight: bold;
  color: gray;
}

#mail-content-font {
  font-size: medium;
  font-weight: 400;
}

#email-template-generator-labels {
  font-size: large;
  font-weight: bold;
}

#email-static-tile-amount,
#email-static-tile-provider {
  color: white;
  text-align: center;
}

#email-inputs {
  border: 1px solid #707070;
  background: #3a3b48;
  color: #ffffff69;
  padding: 10px;
  font-size: medium;
  border-radius: 10px;
  width: 95%;
}

#email-inputs:focus {
  background-color: #3a3b48 !important;
  color: #ffffff69 !important;
}

#email-inputs-text-area:focus {
  background-color: #3a3b48 !important;
  color: #ffffff69 !important;
}

#email-inputs-text-area {
  border: 1px solid #707070;
  background: #3a3b48;
  color: #ffffff69;
  padding: 10px;
  font-size: medium;
  border-radius: 10px;
  width: 95%;
  max-height: 26rem;
  min-height: 26rem;
}

/* CSS for the table */
#consolidated-invoices-table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* border: 1px solid #000000; */

#consolidated-invoices-table th,
td {
  /* border: 1px solid #000000; */
  padding: 8px;
  text-align: center;
}

#consolidated-invoices-table th {
  background-color: #373737;
  color: #ddd;
}

/* CSS for the line break and paragraph */
#consolidated-invoices-table br {
  margin: 20px 0;
}

#consolidated-invoices-table p {
  margin: 0;
}

.popover-template {
  width: fit-content;
}

.popover-template-div {
  background: #232631;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat Alternates-semiBold;
  color: white;
  margin-top: 5px;
  padding: 5px;
}

.popover-template-buttons {
  cursor: pointer;
  margin-right: 10px;
}

.cash-in-bank-settings {
  font-size: 11px;
  color: #ffffff69;
  text-transform: lowercase;
  cursor: auto;
}

#forecast-inputs {
  border: 1px solid #707070;
  background: #3a3b48;
  color: #ffffff69;
  padding: 6px;
  font-size: medium;
  border-radius: 10px;
  width: 7rem;
}

#forecast-inputs:focus {
  background-color: #3a3b48 !important;
  color: #ffffff69 !important;
}

#forecast-inputs-labels {
  font-size: 13px;
  font-weight: bolder;
  max-width: 10rem;
  min-width: 10rem;
  /* margin-right: 1rem; */
  color: #ffffff;
}

#forecast-table-show-more-or-less {
  font-size: 28px;
  font-weight: bold;
  min-width: 2rem;
  margin-right: 1rem;
  margin-bottom: -2rem;
  color: #ffffff;
  cursor: pointer;
}

#forecast-table-show-more-or-less-div {
  margin-top: -2rem;
  display: flex;
  justify-content: end;
  align-items: center;
}

.flex-div-forecast {
  width: 100%;
  padding-bottom: 1rem;
  align-items: flex-start;
}

.forecast-table-div {
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 15px 25px;
  border-radius: 5px;
  margin-bottom: 40px;
  font-size: 11px;
}

.forecast-table-header {
  background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%) 0% 0%
    no-repeat padding-box;
  border-bottom: none;
  color: #b5b5b5;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  border-radius: 0 5px 5px 0;
}

.forecast-table {
  width: 100%;
}

.forecast-table-tr {
  border-color: #808080 !important;
  border-bottom: 0.6px;
  border-style: solid;
}

.forecast-table-section-indentifier {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  font-family: Montserrat Alternates-Bold;
  width: 170px;
  text-align: left !important;
  color: #b5b5b5;
  cursor: pointer;
}

.forecast-table-sub-section-indentifier {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  padding-left: 20px;
  width: 170px;
  text-align: left !important;
  color: #b5b5b5;
  cursor: pointer;
}

.forecast-table-sub-section-data-indentifier {
  position: -webkit-sticky;
  position: sticky;
  padding-left: 50px;
  left: 0px;
  width: 170px;
  text-align: left !important;
  color: #b5b5b5;
  cursor: pointer;
}

.forecast-table-sub-section-data {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  width: 170px;
  text-align: left !important;
  color: #b5b5b5;
  cursor: pointer;
}

.tenantWrapper {
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

.singleTenant {
  text-align: center !important;
  color: white !important;
}

.tenantWrapper {
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

#forecast-manual-adjustments-edit {
  border: 4px solid transparent;
}

#forecast-manual-adjustments-edit:focus {
  border: 2px solid #f9f9f9 !important;
  border-radius: 4px;
}

.bar-graph-forecast {
  padding-top: "2rem";
  padding-bottom: "2rem";
  background-color: "#3a3b48";
  border-radius: 4px;
  margin-bottom: 1rem;
  max-height: 410px;
}

.forecast-details-input {
  max-width: 26rem;
  background-color: #3a3b48;
  margin-left: 1rem;
  padding: 1rem;
  border-radius: 4px;
  max-height: 377px;
}

.forecast-details-input p {
  color: white;
}

.circle-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0.5rem;
  font-weight: bold;
  font-size: medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.green {
  background-color: hsl(120, 63%, 63%);
  color: white;
  border: 1px solid #009500;
  /* Border color for green indicator */
}

.red {
  background-color: rgb(238, 69, 69);
  color: white;
  border: 1px solid red;
  /* Border color for red indicator */
}

.forecast-data-edit {
  cursor: pointer;

  path {
    fill: #33c2df;
  }

  margin-left: 1rem;
}

#accounts-incomes-expense-inputs {
  border: 1px solid #707070;
  background: #3a3b48;
  color: #ffffff69;
  font-size: small;
  border-radius: 10px;
  width: 6rem;
  text-align: center;
  height: 30px !important;
}

.frequency-selector {
  border-radius: 10px !important;
  width: 6rem !important;
}

.table-alert {
  margin-left: 2rem;
  font-size: small;
  color: rgb(245, 195, 32);
}

.table-heading {
  font-size: large;
  color: #ffffff69;
  font-weight: bold;
}

.card-heading {
  font-size: large;
  color: #fff;
  font-weight: 500;
  width: 100%;
  font-weight: bold;
}

.card-white-text {
  color: #fff;
  width: 100%;
  font-weight: 500;
  font-size: 12px !important;
}

.card-grey-text {
  color: #ffffff69 !important;
  width: 100% !important;
  padding: 0px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.card-button-grey {
  color: #ffffff69;
  border-radius: 4px;
  border: 1px solid #6d6d6d !important;
  width: fit-content;
  max-width: 5rem;
}

.card-button-blue {
  color: #fff;
  border-radius: 4px;
  width: fit-content;
  max-width: 5rem;
}

.modal-header-bank-connections {
  border-radius: 4px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.modal-header-bank-connections-text {
  padding-left: 1rem;
  color: white;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: large;
  font-weight: 700;
}

.horizontal-divider-color {
  color: wheat;
}

.list-text-card {
  padding: 4px;
  border-radius: 4px;
  background: #3a3b48;
}

.modal-list {
  list-style-type: disc !important;
}

.ar-ap-list-accordion {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  margin-top: 12px !important;
}

.implement-scroll .table-responsive {
  min-height: 100%;
  scrollbar-width: thin;
}

.implement-scroll .table-responsive::-webkit-scrollbar {
  height: 4px; /* Height of the scrollbar */
  background: #000; /* Background color of the scrollbar track */
}

.implement-scroll .table-responsive::-webkit-scrollbar-track {
  background: #000; /* Background color of the scrollbar track */
}

.implement-scroll .table-responsive::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 2px; /* Rounded corners for the scrollbar thumb */
}

.implement-scroll .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
  cursor: pointer; /* Cursor style on hover */
}
.disputecol .col div > div > div {
  overflow: unset !important;
}

.credit-monitoring-card {
  max-height: 8rem;
  min-height: 8rem;
  min-width: 19.2%;
  max-width: 19.2%;
  border-radius: 4px;
  padding: 1rem;
  margin: 0.5rem;
}

.credit-monitoring-card .title,
.utilisation,
.amount {
  color: white;
  font-weight: bold;
  margin-bottom: 8px;
}

.credit-monitoring-card .title {
  font-size: 18px;
}

.credit-monitoring-card .amount {
  font-size: 24px;
}

.credit-monitoring-card .utilisation {
  font-size: 12px;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.green-circle {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}

.red-circle {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
}

.hover-cursor {
  cursor: pointer;
}

.paymentDownloadWrpper svg {
  margin-left: 5% !important;
}

.modal-close {
  position: absolute;
  display: block;
  right: -18px;
  top: -24px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  z-index: 9;
}

.modal-dialog-payment .modal-dialog {
  max-width: 1000px;
}

.modal-dialog-payment .modal-content {
  background-color: transparent;
  margin: 60px 0px;
}

.bMA-Djf th:first-child {
  display: flex;
}



.modal_form .modal_form_label:focus {
  color: #fff;
}

.modal_form .mb-3 {
  position: relative;
  margin: 0px 0px 20px 0px !important;
}

.modal-dialog-payment .active_class {
  border-radius: 2px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background: linear-gradient(270deg,
      rgb(51, 194, 223) 0%,
      rgb(0, 129, 197) 100%) 0% 0% no-repeat;
  width: 31%;
  cursor: pointer;
}

.modal-dialog-payment .inactive_class {
  color: white;
  width: 31%;
  display: grid;
  background-color: #61606e;
  padding: 3px 10px;
  border-radius: 2px;
  margin: 10px 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* ------------------nuvei payment css----------- */

.sfc-main-container .sfc-cfa-button {
  /* margin: 37px -30px -19px !important;
  right: -30px !important; */
  position: relative;
  background: linear-gradient(270deg,
      rgb(51, 194, 223) 0%,
      rgb(0, 129, 197) 100%) 0% 0% no-repeat;
}

.sfc-main-container fieldset {
  background-color: #3b3a49 !important;
  padding: 0 27px 0px 27px !important;
}

.sfc-main-container .line-container {
  background-color: #3b3a49 !important;
  /* display: none; */
}

.SfcField .cardWidget,
.SfcField .cardWidget input.inputField.invalid {
  color: #fff !important;
}

.sfc-main-container .pm-details {
  background-color: #3b3a49 !important;
}

.sfc-main-container .main-title {
  display: none;
}


.sfc-main-container .input-row input,
.sfc-main-container .input-row #ccCardNumber,
.sfc-main-container .input-row #ccExpYear,
.sfc-main-container .input-row #ccCVV {
  background-color: #fff !important;
}

.sfc-main-container .form-radio-style.item-selected {
  border: none !important;
}

div#sfc-main.sfc-main-container div.sfc-pm-container label {
  color: #b1aeab;
}



.SfcField .cardWidget input[type="text"] {
  color: #fff !important;
}

.sfc-focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
  background-color: #fff !important;
}

.sfc-main-container input[type="date"],
.sfc-main-container input[type="password"] {
  color: #fff !important;
}

.sfc-main-container .input-row {
  margin-bottom: -8px !important;
}
.sfc-main-container .input-row input{
  border-radius: 20px;
}
.Bankcards {
  width: 140px;
  border: 1px solid #d4d7dc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 5px;
  cursor: pointer;
  height: 68px;
}

.Bankcards img {
  width: 54px;

  border-radius: 5px;
}

.active_Bankcards {
  cursor: pointer;
  width: 140px;
  border: 1px solid #d4d7dc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 5px;
  border-top: 5px solid #10c065;
  height: 70px;

  /* background-color: #10c065; */
}

.active_Bankcards img {
  width: 54px;
  border-radius: 5px;
}

/* .payment-body .sfc-main-container .sfc-cfa-button {
  margin: 17px 0px 17px -32px !important;
} */

.was-validated .form-control:valid {
  background-image: none !important;
  border-color: transparent !important;
}

.was-validated .form-control:valid:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.was-validated .form-control:invalid {
  background-image: none !important;
}

.invalid-feedback {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.modal_form .text-danger {
  font-size: 11px !important;
  position: absolute;
  bottom: -18px !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

.ach_modal .modal-content {
  background-color: rgb(59, 58, 73) !important;
  padding: 30px !important;
  width: 385px !important;
  color: #909090;
}

.ach_modal .modal-content .ach__modal_button {
  position: absolute;
  display: block;
  right: -26px;
  top: -20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #000;
}

.ach_modal {
  background-color: rgba(244, 246, 248, 0.7);
  display: flex;
}

.fade .ach_modal .modal .show {
  display: flex !important;
}

.ach_modal .modal-footer {
  flex-wrap: nowrap !important;
  margin-bottom: -10px;
}

.ach_modal .modal-footer .close_button {
  width: -webkit-fill-available;
}

.ach_modal .modal-content {
  left: 130px;
  top: 195px;
}

.paymentDownloadWrpper svg {
  margin-left: 5% !important;
}

.paymentDownloadWrpper .fszKJU {
  width: 170px !important;
}

.billing_form_outer .billingForm {
  color: #fff;
  background-color: rgb(59, 58, 73);
  border-radius: 20px;
  margin-top: 15px;
  cursor: pointer;
}

.billing_form_outer {
  margin: 20px 0px;
}

.downTrans svg {
  width: 22px !important;
}

.billingFormWrapp {
  position: relative;
  margin: auto;
  width: 35%;
  padding: 25px;
  background: 0% 0% no-repeat padding-box padding-box rgb(68, 69, 81);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 36px;
  border-radius: 10px;
  opacity: 1;
  z-index: 2000;
}

.billing_form_outer input {
  width: 100%;
  height: 40px;
  padding: 10px;
  background: 0% 0% no-repeat padding-box padding-box rgb(58, 59, 72);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 26px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat Alternates";
  letter-spacing: 0px;
}

.border-for-variance-in-budgeting {
  border-right: 0.7px solid rgba(255, 255, 255, 0.777) !important;
}

.border-for-variance-in-budgeting:last-child {
  border-right: 0px !important;
}

.month-formatter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month-formatter-plus-minus {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: large;
  font-weight: bold;
}



.react-datepicker-popper {
  z-index: 10000 !important;
}

.sfc-main-container input[type=checkbox]:checked:before {
  top: 1px !important;
}


.modal_form .form-label {
  margin-bottom: 0px !important;
  font-size: 13px;
}

.modal_form .form-control {
  margin: 0px 0px -7px 0px;
}

.payment-body {
  transform: translate(-50%, -50%);
}
.circle-indicator-budget {
  font-weight: bold;
  font-size: small;
}

.greenn {
  color: hsl(120, 63%, 63%);
  background-color: none;
  border: none;
}

.redd {
  color: rgb(238, 69, 69);
  background-color: none;
  border: none;
}

.border-for-variance-in-budgeting {
  border-right: 0.7px solid rgba(255, 255, 255, 0.777) !important;
}

.border-for-variance-in-budgeting:last-child {
  border-right: 0px !important;
}

.month-formatter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month-formatter-plus-minus {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: large;
  font-weight: bold;
}

.adv-payment-modal input {
  height: 40px;
  padding: 10px;
  background: 0% 0% no-repeat padding-box padding-box rgb(58, 59, 72);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 26px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat Alternates";
  letter-spacing: 0px;
  color: rgb(144, 144, 144);
}
.dipositeCustomerSelect .css-13cymwt-control , .dipositeCustomerSelect .css-t3ipsp-control{

  /* height: 53px; */
  /* padding: 10px; */
  background: 0% 0% no-repeat padding-box padding-box rgb(58, 59, 72);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 26px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat Alternates";
  letter-spacing: 0px;
   color: rgb(144, 144, 144); 
   height: 40px;
   justify-content: center;
   align-items: center;
}
.dipositeCustomerSelect  .css-1dimb5e-singleValue{
  color: #909090 !important;
}
.dipositeCustomerSelect .css-1u9des2-indicatorSeparator{
  height: 23px;
}
.dipositeCustomerSelect .css-1xc3v61-indicatorContainer{
  padding: 00 10px 3px 7px;
}
.dipositeCustomerSelect .css-15lsz6c-indicatorContainer{
  padding: 00 10px 3px 7px;
}
.dipositeCustomerSelect .css-1fdsijx-ValueContainer{
  top: -6px
}
.dipositeCustomerSelect .css-19bb58m{  
  height: 33px;
}
.dipositeCustomerSelect .css-19bb58m{
  color: #707070 !important;
}

.dipositeCustomerSelect .css-166bipr-Input input{
  color: #909090 !important;
}

.dipositeCustomerSelect .css-1cfo1cf input{
  color: #909090 !important;
}

.dipositeCustomerSelect .css-1n6sfyn-MenuList{
  color: #909090;
  background: #3a3b48;
}
.dipositeCustomerSelect .css-qr46ko{
  color: #909090;
  background: #3a3b48;
}
.dipositeCustomerSelect .css-d7l1ni-option{
  
  background-color: rgb(34, 34, 34);
}
.dipositeCustomerSelect .css-1nmdiq5-menu{
  scrollbar-color: #60606c #000;
  font-size: 11px;
  color: #909090;
  background: #3a3b48;
}

.surcharge-chart tbody tr:last-child{
  border-bottom: none !important;
}
#nuveiCheckout{
  position: relative;

  
}
.payment-Loader{
  position: absolute;
  z-index: 10;
  top:70%;
  left: 32%;

}
.nuveiCheckout-blurred{
  filter: blur(8px);
  -webkit-filter: blur(8px);
}


/* Firefox scrollbar styles */
.scrollbar-payment {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color:#60606c #000; 
  scroll-behavior: smooth;/* Thumb and track color */
}
/* .add-colon::before{
content: ":";
} */
.add-colonll::before{
  content: ":";
  color: rgb(195, 195, 195);
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .add-colonll{
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .add-colonll td{
    width: 100%;
    margin-left: auto;
    /* padding: 0; */
    display: block;
    text-align: right;
  }

  .edit-inv-amt[type=number]::-webkit-inner-spin-button,
.edit-inv-amt[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.edit-inv-amt[type=number] {
  -moz-appearance: textfield
}

.edit-inv-amt {
  background-color: #3b3a49;
  border: solid 1px #ced4da;
  border-radius: 5px;
  color: rgb(181, 181, 181);
}

.guestPaymentLoader{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%);
}
.modal-dialog-payment{
  z-index: 3000 !important;
}


.form-radio-style .item-selected{
  border: solid 1px #d4d7dc !important;
}

.sfc-main-container .upm-list .form-radio-style.item-selected{
  border: solid 1px white !important;
}
.guestPaymentModal .sfc-main-container .line-container {
  background-color: #3b3a49 !important;
  display: none;
}

.paymeny-approved{
  position: relative;
  margin-top: 10px;
}
.approved-img{
  position: absolute;
  position: absolute;
  bottom: 45%;
  left: 50%;
  transform: translate(-50%);
  height: 130px;
}
.draggable-modal{
  
  cursor: move;
}
.otp-modal-usermanagement .modal-content{
  width: 70%;
}

.otp-modal-usermanagement .modal-dialog-centered{
  justify-content: center;
}
.otp-modal{
  z-index: 4000 !important;
  padding: 2% !important;
  position: absolute;
}
.close-Otp-modal{
  position: relative;
  left: 45%;
  background: transparent;
  border: none;
  color: #ced4da;
  font-weight: bold;
  top: 5px;
}

.otp-modal-logo{
  /* width: 150px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.otp-modal-logo img{
  width: 150px !important;

}
/* otp_modal_container p{
color: #ced4da;
} */
.otp-modal-text{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.choose-otp-method{
  display: flex;
      /* box-shadow: #767782 0px 8px 24px; */
      box-shadow:rgb(17 18 24) 0px 4px 8px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      padding: 8px;
      border-radius: 5px;
      /* width: 80%; */
      margin-top: 10px;
      cursor: pointer;

}
.otp-messag-logo{
  /* border: solid 1px white; */
  padding: 10px 13px;
  border-radius: 50%;
  color:  rgb(181, 181, 181);
  background: #767782;
}

.opt-message-text{
padding-left: 10px;
display: inline-flex;
flex-direction: column;
text-align: start;
color:  rgb(181, 181, 181);
}

.otp-sms-container{
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.otp-error{
  color: #ff7177 !important;
  font-size: 13px;
}

.otp-modal-blur{
  filter: blur(8px);
  -webkit-filter: blur(8px);
  pointer-events: none;
}

.create-user-otp-modal .modal-content{
  width: 70%;
}

.create-user-otp-modal .modal-dialog{
left: 60px;
}