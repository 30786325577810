body {
  background-color: #232631;
  font-family: Montserrat Alternates, Serif, Sans-serif;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  background: none;
  border: none;
}

.show > .btn-secondary.dropdown-toggle {
  color: #909090;
  background-color: transparent;
  border-color: none;
}

#onSelectedDueDayOfWeek,
#periodicWeeklydays {
  font-family: Montserrat Alternates;
  width: fit-content;
  padding: 0px;
  border-radius: 0px !important;
  margin-left: 4px;
  margin-right: 4px;
}

.filterCustomerAndEmailSelect {
  font-family: Montserrat Alternates;
  padding: 0px;
  border-radius: 0px !important;
  margin-left: 4px;
  margin-right: 4px;
}

.dynamic-variable {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 20px;
  background-color: #232631;
  border: 1px solid white;
}

.AssignYesOrNo {
  font-size: small;
  cursor: pointer;
}

.forecast-input-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cash-in-header {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  text-transform: lowercase;
  font-family: Montserrat Alternates, Serif, sans-serif !important;
}

.cash-in-tooltip {
  font-size: 11px;
  line-height: 24px;
  font-style: normal;
  color: #ffffff;
  text-transform: lowercase;
}

.cash-card-label {
  border-bottom: none;
  color: #909090;
  font-size: 14px;
  line-height: 25px;
  font-family: Montserrat Alternates-Bold;
  font-style: normal;
  background: none;
}
